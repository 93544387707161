import React from "react"

import { NewIssueStandalonePage } from "components/maintenance/issues/new"
import { MaintenancePage } from "components/maintenance/layout"

import { MaintenancePermissions } from "types/issue"

const Page = () => {
  return (
    <MaintenancePage
      title="Report An Issue"
      accessControl={(permissions: MaintenancePermissions) =>
        permissions.mayCreateIssues
      }
    >
      <NewIssueStandalonePage />
    </MaintenancePage>
  )
}

export default Page
